<template>
  <section class="container-fluid mt-4 admin-dashboard">
    <div class="">
      <h4 class="font-weight-bold text-left d-flex">ตั้งค่าระบบ</h4>
      <div class="d-flex mt-3 pb-2 border-bottom menu">
        <router-link
          :to="{ name: 'admin.national_plan_settings' }"
          class="item mx-4 font-weight-bold link-unstyle text-ipepp"
          :class="{ active: currentTab == 'admin.national_plan_settings' }"
        >
          แผนปฏิบัติราชการ
        </router-link>
        <router-link
          :to="{ name: 'admin.peno.import' }"
          class="item mx-4 font-weight-bold link-unstyle text-ipepp"
          :class="{ active: currentTab == 'admin.peno.import' }"
        >
          นำเข้าข้อมูลผู้ใช้ PEnO
        </router-link>
        <router-link
          :to="{ name: 'admin.manage_users' }"
          class="item mx-4 font-weight-bold link-unstyle text-ipepp"
          :class="{ active: currentTab == 'admin.manage_users' }"
        >
          จัดการผู้ใช้งาน
        </router-link>
        <router-link
          :to="{ name: 'admin.toggle_api' }"
          class="item mx-4 font-weight-bold link-unstyle text-ipepp"
          :class="{ active: currentTab == 'admin.toggle_api' }"
        >
          จัดการการใช้งาน API
        </router-link>
      </div>
    </div>
    <transition mode="out-in" name="fade">
      <router-view class="animated" />
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AdminDashboard',

  data() {
    return {
      currentTab: this.$route.name,
    };
  },
  watch: {
    $route(to, from) {
      this.currentTab = to.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';

.admin-dashboard {
  min-height: 100%;
  height: 1px;

  .menu {
    overflow-x: auto;
    overflow-y: hidden;

    .item.active {
      color: $red-color !important;
    }
  }
}
</style>
